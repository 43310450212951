<template>
  <div>
    <ViewTemplateWithTable :panel="panel" :templateList="templateList" :buttonRemove="false" :filters="filters">
      <div slot="content-buttons-table-header">
        <Button _key="btnCancelBillet" :title="'Cancelar'" type="danger" size="small" :clicked="confirmCancel" />
      </div>
    </ViewTemplateWithTable>

    <Modal title="Tem certeza que deseja cancelar?" :width="570" v-show="showModal('cancelModal')">
      <Alert type="warning">
        <span>
          Atenção, certifique-se que está cancelando o Boleto correto, está ação é irreversível!
        </span>
      </Alert>
      <div>
        <i class="fa-solid fa-triangle-exclamation icon-warning"></i> Válido somente para o boletos com Status Emitido e
        Registrado.
      </div>
      <Confirmation :isModal="false" title="Você tem certeza?" type="danger" :confirmed="cancel" />
    </Modal>
    <Modal title="Histórico" :width="800" v-show="showModal('historyBillet')">
      <HistoryBillet />
    </Modal>
  </div>
</template>

<script>

import ViewTemplateWithTable from "@nixweb/nixloc-ui/src/component/template/ViewTemplateWithTable.vue";
import Confirmation from "@nixweb/nixloc-ui/src/component/shared/Confirmation.vue";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup.vue";
import SelectStatic from "@nixweb/nixloc-ui/src/component/forms/SelectStatic";

import HistoryBillet from '../../../components/modules/billing/history-billet/HistoryBillet.vue'

import { mapGetters, mapMutations, mapActions, mapState } from "vuex";

export default {
  name: "BilletListView",
  components: {
    ViewTemplateWithTable,
    Confirmation,
    Alert,
    Modal,
    Button, RadioGroup, SelectStatic, HistoryBillet
  },
  data() {
    return {
      id: "",
      panel: {
        module: "Boleto Bancário",
        title: "Boleto",
        showFilter: true,
        showSearch: true,
        showButtons: true,
      },
      templateList: {
        urlGetApi: "/api/v1/billing/billet/get-all",
        urlCancel: "/api/v1/billing/billet/cancel-billet",
        showChecks: true,
        headerTable: [
          {
            field: "urlLogo",
            container: "nixloc-bank-logo",
            type: "image",
            classCssBody: "img-bank",
          },
          {
            title: "Emissão",
            field: "registeredDate",
            type: "dateTime",
            iconSearch: false,
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            field: "recipientName",
            title: "Cliente",
            type: "link",
            iconSearch: true,
            eventName: "historyBillet"
          },
          {
            field: "document",
            title: "Documento",
            type: "text",
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            title: "Vencimento",
            field: "dueDate",
            type: "text",
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            field: "statusName",
            title: "Status",
            type: "class",
            fieldComparison: "statusName",
            classCssTitle: "text-center",
            classCssBody: [
              {
                classCss: "text-center badge badge-primary",
                fieldComparison: "Emitido",
              },
              {
                classCss: "text-center badge badge-primary",
                fieldComparison: "Alteração Solicitada",
              },
              {
                classCss: "text-center badge badge-dark-primary",
                fieldComparison: "Registrado",
              },
              {
                classCss: "text-center badge badge-success",
                fieldComparison: "Liquidado",
              },
              {
                classCss: "text-center badge badge-danger",
                fieldComparison: "Cancelado",
              },
              {
                classCss: "text-center badge badge-danger",
                fieldComparison: "Rejeitado",
              },
              {
                classCss: "text-center badge badge-warning",
                fieldComparison: "Baixa",
              },
              {
                classCss: "text-center badge badge-warning",
                fieldComparison: "Baixa Solicitada",
              },
            ],
          },
          {
            field: "hasShippingName",
            title: "Remessa",
            type: "class",
            fieldComparison: "hasShippingName",
            classCssTitle: "text-center",
            classCssBody: [
              {
                classCss: "text-center badge-round badge-round-warning",
                fieldComparison: "Pendente",
              },
              {
                classCss: "text-center badge-round badge-round-dark-primary",
                fieldComparison: "Gerada",
              },
              {
                classCss: "text-center badge-round badge-round-danger",
                fieldComparison: "Cancelada",
              },

            ],
          },
          {
            title: "Valor",
            field: "value",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            type: "currency",
          },
        ],
      },
      filters: [
        {
          type: "date",
          title: "Emissão",
          field: "RegisteredDate",
          value: "",
        },
        {
          type: "options",
          title: "Remessa",
          field: "HasShippingName",
          options: [
            { text: "Gerada", value: "Gerada" },
            { text: "Pendente", value: "Pendente" },
          ],
          value: [],
        },
        {
          type: "options",
          title: "Status",
          field: "StatusName",
          options: [
            { text: "Alteração Solicitada", value: "Alteração Solicitada" },
            { text: "Alterado", value: "Alterado" },
            { text: "Baixa", value: "Baixa" },
            { text: "Baixa Solicitada", value: "Baixa Solicitada" },
            { text: "Cancelado", value: "Cancelado" },
            { text: "Emitido", value: "Emitido" },
            { text: "Liquidado", value: "Liquidado" },
            { text: "Rejeitado", value: "Rejeitado" },
            { text: "Registrado", value: "Registrado" },
          ],
          value: [],
        },
        {
          type: "date",
          title: "Vencimento",
          field: "_DueDate",
          value: "",
        },
        {
          type: "decimal",
          title: "Valor",
          field: "Value",
          valueStart: 0,
          valueEnd: 0,
        },
      ],
    };
  },
  created() {
    if (this.filter) {
      if (this.filter == "delayed") this.propsParam.filter = "Pendente";
      if (this.filter == "today") this.propsParam.filter = "Hoje";
      if (this.filter == "week") this.propsParam.filter = "Semana Atual";
      if (this.filter == "month") this.propsParam.filter = "Mês Atual";
      if (this.filter == "year") this.propsParam.filter = "Ano Atual";
    }

    if (this.status) {
      let status = "";
      if (this.status == "emitted") status = "Emitido";
      if (this.status == "registered") status = "Registrado";
      if (this.status == "delayed") status = "Em atraso";
      if (this.status == "paid") status = "Liquidado";

      return this.select = { "content": status, "id": status };
    }

  },
  computed: {
    ...mapGetters("generic", ["showModal", "event"]),
    ...mapState("generic", ["selected"]),
  },
  methods: {
    ...mapMutations("generic", ["openModal", "hideModal", "removeLoading"]),
    ...mapActions("generic", ["putAllApi"]),
    confirmCancel() {
      this.removeLoading(["btnCancelBillet"]);
      this.openModal("cancelModal");
    },
    cancel() {
      let params = {
        url: this.templateList.urlCancel,
        selected: this.selected,
      };
      this.putAllApi(params).then((response) => {
        if (response.success) {
          this.hideModal("generateInvoice");
        }
        this.removeLoading(["confirm"]);
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "historyBillet")
          this.openModal("historyBillet");
      },
      deep: true,
    },
    select: {
      handler(select) {
        this.propsParam.status = select.id;
      },
      deep: true,
    },
  },
};
</script>
<style>
.icon-warning {
  color: darkorange;
}

.div-tag {
  margin-top: 10px;
  margin-bottom: 20px;
}
</style>